import React from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import headerStyles from './header.module.scss'
import pacmanBlue from '../resources/pacmanBlue.png'
import pacmanRed from '../resources/pacmanRed.png'
import ProjectsPage from '../pages/projects'

const About =()=>{
    <link href="https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap" rel="stylesheet"></link>
    return(
            <div className={headerStyles.about}>
                <div className={headerStyles.horizontalAlignment}>
                    <img src={pacmanRed}className={headerStyles.pacmanImageLeft}></img>
                </div>
                <div >
                    <h2 className={headerStyles.textAlignMiddle}>I'm Hitesh</h2>
                    <h3 className={headerStyles.textAlignMiddleWithTop}>a game developer</h3>
                </div>
                <div className={headerStyles.horizontalAlignment}>
                    <img  src={pacmanBlue}className={headerStyles.pacmanImageRight}></img>
                </div>

            </div>
    )
}
export default About