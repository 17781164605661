import React from 'react'
import Layout from '../components/layout'
import {graphql} from 'gatsby'
import { documentToReactComponents} from '@contentful/rich-text-react-renderer'
import indexStyles from './index.module.scss'
import About from '../components/aboutcomponent'
import Head from '../components/head'
export const query = graphql`
    query{
        allContentfulAbout{
            edges{
                node{
                    aboutMyself{
                        json
                    }
                    skills{
                        json
                    }
                }
            }
        }
    }
`

const IndexPage = (props)=>{
    <link href="https://fonts.googleapis.com/css?family=Open+Sans|Press+Start+2P&display=swap&subset=cyrillic,greek" rel="stylesheet"></link>

    return (
        <Layout>
            <Head title="Home"/>
            <About/>
            <div className={indexStyles.mainContent}>
            <h1 className={indexStyles.headingFont}>Little bit about myself</h1>
                {props.data.allContentfulAbout.edges.map((edge)=>{
                    return(
                        <div>
                            {documentToReactComponents(edge.node.aboutMyself.json)}
                        
                            {documentToReactComponents(edge.node.skills.json)}
                        </div>
                    // documentToReactComponents({edge.node.duties.json})
                    )
                })}
            </div>
            
        </Layout>
    )
    
}
export default IndexPage